<template>
  <!-- 已处理审批 -->
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 审批详情 -->
      <approval-detail ref="approvalDetail" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        tid="1"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <!-- 发起时间 -->
        <span slot="createTime" slot-scope="text, record" :title="parseTime(record.createTime)">
          {{ parseTime(record.createTime) }}
        </span>
        <!-- 完成时间 -->
        <span slot="completeTime" slot-scope="text, record" :title="parseTime(record.completeTime)">
          {{ parseTime(record.completeTime) }}
        </span>
        <!-- 摘要 -->
        <span slot="summary" slot-scope="text, record">
          <div v-for="(item, index) in record.formFields" :key="index" style="text-align: left">
            <span>{{ item.filedText }}:</span>
            <span>{{ item.fieldValue }}</span>
          </div>
        </span>
        <!-- 审批状态 -->
        <span slot="approvalStatus" slot-scope="text, record" :title="approvalStatusFormat(record)">
          {{ approvalStatusFormat(record) }}
        </span>
        <!-- 操作 -->
        <span slot="operation" slot-scope="text, record">
          <template v-hasPermi="['tenant:approval:myApproved:query']">
            <a-divider type="vertical" />
            <a @click="$refs.approvalDetail.handleDetail(record)"> <a-icon type="edit" />查看 </a>
          </template>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { myApproved } from '@/api/iot/approvalProcessForm'
import approvalDetail from '../approvalDetail'
export default {
  name: 'MyApproved',
  components: { approvalDetail },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 是否可摊销字典
      isAmortizeOptions: [],
      // 查询参数
      queryParam: {
        applicantId: null,
        expenseType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '审批编号',
          dataIndex: 'approvalNo',
          ellipsis: true,
          align: 'center',
          width: 250
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '摘要',
          dataIndex: 'summary',
          scopedSlots: { customRender: 'summary' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发起人',
          dataIndex: 'promoter',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发起时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '完成时间',
          dataIndex: 'completeTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审批状态',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      approvalStatusTypeOptions: []
    }
  },
  filters: {},
  created() {
    this.getData()
  },

  computed: {},
  watch: {},
  methods: {
    async getData() {
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      await this.getDictData()
      this.getList()
    },
    async getDictData() {
      this.loading = true
      await this.getDicts('iot_approval_status_type').then((response) => {
        this.approvalStatusTypeOptions = response.data
      })
      this.loading = false
    },
    /** 查询费用记录列表 */
    async getList() {
      this.loading = true
      await myApproved(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 审批状态格式化显示
    approvalStatusFormat(row, column) {
      return this.selectDictLabel(this.approvalStatusTypeOptions, row.approvalStatus)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        applicantId: undefined,
        expenseType: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
</script>
