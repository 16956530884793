var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("approval-detail", {
            ref: "approvalDetail",
            on: { ok: _vm.getList },
          }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              tid: "1",
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "createTime",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.parseTime(record.createTime) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.createTime)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "completeTime",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.parseTime(record.completeTime) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.completeTime)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "summary",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.formFields, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { "text-align": "left" } },
                        [
                          _c("span", [_vm._v(_vm._s(item.filedText) + ":")]),
                          _c("span", [_vm._v(_vm._s(item.fieldValue))]),
                        ]
                      )
                    }),
                    0
                  )
                },
              },
              {
                key: "approvalStatus",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.approvalStatusFormat(record) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.approvalStatusFormat(record)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      [
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$refs.approvalDetail.handleDetail(
                                  record
                                )
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "edit" } }),
                            _vm._v("查看 "),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }